@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
}
html {
  scroll-behavior: smooth;
}

.upper-nav {
  background: linear-gradient(270deg, #000 9.3%, #210655 57.48%, #000 100%);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.18);
  position: sticky;
  top: 0;
  width: 100%;
}

.header-bg {
  background: linear-gradient(270deg, #000 0%, #00ca9c 48.96%, #000 100%);
}

.search-blend {
  opacity: 0.99;
  background: #1d113b;
  mix-blend-mode: screen;
}

.banner-bg {
  background-image: url("../public/images/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 600px;
}

.transformation-banner {
  background-image: url("../public/images/transformation/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.development-banner {
  background-image: url("../public/images/web-development/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.data-analytics-banner {
  background-image: url("../public/images/data-analytics/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.branding-banner {
  background-image: url("../public/images/digital-branding/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.security-banner {
  background-image: url("../public/images/cyber-security/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.case-studio {
  background-image: url("../public/images/studio/img-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.step-active {
  background: linear-gradient(180deg, #13003a 0%, rgba(19, 0, 58, 0) 100%);
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.08);
}

.services-card {
  background: linear-gradient(180deg, #000 33.07%, #02a78e 102.4%);
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.14);
}

.footer-bg {
  background: linear-gradient(180deg, #100138 23.38%, #10b09c 128.15%);
}

.it-service {
  background-image: url("../public/images/it-service.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 200px;
}

.talk-btn {
  background: linear-gradient(179deg, #00dcaa -30.29%, #000 99.31%);
}

/* the slides */
.slick-slide {
  margin: 0 10px;
}
/* the parent */
.slick-list {
  margin: 0 -10px;
}

.result-box {
  border-radius: 30px;
  border: 1px solid #40f896;
  background: linear-gradient(180deg, #000 33.07%, #02a78e 102.4%);
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.14);
}

.contact-bg {
  background-image: url("../public/images/bg-contact.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.services-bg {
  background-image: url("../public/images/bg-services.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.case-studies-bg {
  background-image: url("../public/images/bg-casestudy.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

@layer base {
  .nav-items {
    @apply font-raleway text-white 2xl:text-2xl xl:text-xl text-base font-semibold;
  }
  .nav-items-mob {
    @apply font-raleway text-base font-semibold uppercase;
  }
  .step-btn {
    @apply w-full h-20 flex items-center justify-center bg-secondary text-white font-raleway font-semibold text-xl rounded-lg;
  }
  .step-btn-active {
    @apply step-active w-full h-20 flex items-center justify-center text-white font-raleway font-semibold text-xl rounded-lg;
  }
  .company-icon {
    @apply w-24 h-24 rounded-full border border-primary text-primary font-raleway text-2xl font-semibold p-4 flex items-center justify-center;
  }
  .company-desc {
    @apply text-white font-montserrat text-xl font-normal mt-8 text-center;
  }
  .company-card-head {
    @apply font-raleway lg:text-3xl text-xl text-white text-center;
  }
  .company-card-desc {
    @apply font-raleway lg:text-xl text-base text-white text-center mt-3;
  }
  .testimonials-main {
    @apply px-8 py-5 xl:h-72 h-[22rem] flex flex-col items-center justify-center border border-primary rounded-[30px] shadow-[0px_10px_19px_1px_rgba(0,_0,_0,_0.08)];
  }
  .testimonials-text {
    @apply mt-4 font-raleway xl:text-2xl text-xl text-white;
  }
  .testimonials-profile {
    @apply bg-gray-200 h-12 w-12 rounded-full;
  }
  .testimonials-author {
    @apply font-raleway text-xl text-white font-bold;
  }
  .testimonials-dest {
    @apply font-raleway text-white text-sm;
  }
}
